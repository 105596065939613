<template>
  <v-container class="fill-height">
    <v-row v-if="loading" align="center" justify="center" class="fill-height">
      <v-progress-circular
        :size="60"
        :width="5"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>

    <v-card v-else class="d-md-flex justify-md-space-between pa-6 fill-height">
      <v-col col="12" md="6" class="fields-custom-width pa-0">
        <v-form ref="form" v-model="valid" @submit.prevent="submit">
          <v-select
            v-model="withdrawModel.accountId"
            :rules="currencyRules"
            item-value="accountId"
            required
            :items="currencies"
            filled
            hide-details="auto"
            class="theme-custom-input fields-custom-width mb-5"
            :menu-props="{
              contentClass:
                'theme-custom-input-dropdown-menu withdraw-dropdown-menu',
            }"
            @input="getSelectedCurrencyData"
            append-icon="$arrowDown"
          >
            <template v-slot:selection="{ item }">
              <img :src="item.svglogo" width="24" height="24" class="me-2" />
              <span class="gray12--text me-1">{{ item.iso }}</span>
              <span class="font-regular-12 gray7--text"
                >({{ item.network }})</span
              >
            </template>

            <template v-slot:item="{ active, item, attrs, on }">
              <template>
                <v-list-item v-on="on" #default>
                  <img
                    :src="item.svglogo"
                    width="24"
                    height="24"
                    class="me-2"
                  />
                  <span class="gray12--text me-1">{{ item.iso }}</span>
                  <span class="font-regular-12 gray7--text"
                    >({{ item.network }})</span
                  >
                </v-list-item>
              </template>
            </template>
          </v-select>

          <div class="font-regular-10 gray7--text text-right mb-1 pe-3">
            {{ $t("panel.dashboardPage.withdrawalPage.available") }}
            {{ selectedCurrency.remainAmount }}
            {{ selectedCurrency.iso }}
          </div>

          <!--          <v-text-field-->
          <!--            :value="selectedCurrency.remainAmount.toFixed(8) || 0"-->
          <!--            v-separator="{-->
          <!--              dot: { afterDot: '18' },-->
          <!--            }"-->
          <!--            filled-->
          <!--            reverse-->
          <!--            disabled-->
          <!--            hide-details="auto"-->
          <!--            :suffix="$t('panel.dashboardPage.withdrawalPage.amount')"-->
          <!--            class="theme-custom-input fields-custom-width amount-message-dir mb-8"-->
          <!--          >-->
          <v-text-field
            v-model="withdrawModel.amount"
            v-separator="{
              dot: { afterDot: '18' },
            }"
            :rules="amountRules"
            required
            filled
            :hint="usdValue"
            persistent-hint
            reverse
            :suffix="$t('panel.dashboardPage.withdrawalPage.amount')"
            class="theme-custom-input fields-custom-width amount-message-dir"
          >
            <template v-slot:prepend-inner>
              <v-btn
                text
                :ripple="false"
                color="transparent"
                width="30"
                min-width="30"
                height="21"
                class="ms-2"
                @click="addMaxAmount"
              >
                <span
                  class="font-semiBold-14 primary--text text-decoration-underline"
                >
                  {{ $t("panel.dashboardPage.withdrawalPage.max") }}
                </span>
              </v-btn>
            </template>
          </v-text-field>

          <!--          <div class="font-regular-10 gray10&#45;&#45;text text-right mt-1 mb-5 pe-4">-->
          <!--            {{ selectedCurrency.localCurrency }}-->
          <!--            {{ $t("panel.dashboardPage.withdrawalPage.value") }}-->
          <!--            ${{ withdrawModel.amount * selectedCurrency.instantAmount }}-->
          <!--          </div>-->

          <div class="mb-5">
            <span class="gray9--text me-2">
              {{ $t("panel.dashboardPage.withdrawalPage.network") }}
            </span>
            <span class="gray12--text">
              {{ selectedCurrency.network }}
            </span>
          </div>

          <v-text-field
            v-model="withdrawModel.withdrawAddress"
            :rules="walletAddressRules"
            required
            :label="$t('panel.dashboardPage.withdrawalPage.walletAddress')"
            filled
            hide-details="auto"
            class="theme-custom-input fields-custom-width mb-5"
          >
          </v-text-field>

          <!--          <v-card-->
          <!--            color="gray1"-->
          <!--            class="mb-5 px-4 radius-3 d-flex align-center"-->
          <!--            height="64"-->
          <!--          >-->
          <!--            <v-row no-gutters align="center" justify="space-between">-->
          <!--              <div class="font-12-regular gray7&#45;&#45;text">-->
          <!--                {{ $t("panel.dashboardPage.withdrawalPage.receivedAmount") }}-->
          <!--              </div>-->
          <!--              <div v-if="selectedCurrency.remainAmount" class="text-right">-->
          <!--                <span class="font-medium-14 gray10&#45;&#45;text me-1">{{-->
          <!--                  selectedCurrency.remainAmount.toFixed(8)-->
          <!--                }}</span>-->
          <!--                <span class="font-medium-14 gray10&#45;&#45;text">{{-->
          <!--                  selectedCurrency.iso-->
          <!--                }}</span>-->
          <!--                <div class="asset-equivalent">-->
          <!--                  <span class="font-regular-12 gray7&#45;&#45;text">-->
          <!--                    ≈-->
          <!--                    {{-->
          <!--                      (-->
          <!--                        selectedCurrency.remainAmount *-->
          <!--                        selectedCurrency.instantAmount-->
          <!--                      ).toFixed(5)-->
          <!--                    }}-->
          <!--                  </span>-->
          <!--                  <span class="font-regular-12 gray7&#45;&#45;text">-->
          <!--                    {{ selectedCurrency.localCurrency }}-->
          <!--                  </span>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </v-row>-->
          <!--          </v-card>-->

          <!--        <template v-if="selectedCurrency.isSecondLayer">-->
          <!--          <v-checkbox-->
          <!--            v-model="noTag"-->
          <!--            dense-->
          <!--            :ripple="false"-->
          <!--            hide-details="auto"-->
          <!--            off-icon="$emptyCheckbox"-->
          <!--            on-icon="$activeCheckbox"-->
          <!--            class="mb-3"-->
          <!--          >-->
          <!--            <template v-slot:label>-->
          <!--              <div class="gray8&#45;&#45;text font-regular-12">-->
          <!--                {{ $t("panel.dashboardPage.withdrawalPage.noTagNeeded") }}-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </v-checkbox>-->

          <!--          <v-text-field-->
          <!--            v-if="!noTag"-->
          <!--            v-model="withdrawModel.tag"-->
          <!--            :rules="tagRules"-->
          <!--            required-->
          <!--            :label="$t('panel.dashboardPage.withdrawalPage.tag')"-->
          <!--            filled-->
          <!--            hide-details="auto"-->
          <!--            class="theme-custom-input fields-custom-width mb-5"-->
          <!--            type="tel"-->
          <!--            @keypress="filterOnlyNumber"-->
          <!--          >-->
          <!--          </v-text-field>-->
          <!--        </template>-->

          <!--          <div class="fee-card px-4 py-3 mb-5">-->
          <!--            <div class="d-flex align-center justify-space-between mb-3">-->
          <!--              <span class="font-regular-12 gray7&#45;&#45;text">-->
          <!--                {{ $t("panel.dashboardPage.withdrawalPage.networkFee") }}-->
          <!--              </span>-->

          <!--              <div>-->
          <!--                <span class="me-2 font-regular-12 gray8&#45;&#45;text">-->
          <!--                  {{ selectedCurrency.feeAmount }}-->
          <!--                </span>-->

          <!--                <span class="me-2 font-regular-12 gray8&#45;&#45;text">-->
          <!--                  {{ selectedCurrency.iso }}-->
          <!--                </span>-->
          <!--              </div>-->
          <!--            </div>-->

          <!--            <v-divider></v-divider>-->

          <!--            <div class="d-flex align-center justify-space-between mt-3">-->
          <!--              <span class="font-regular-12 gray7&#45;&#45;text">-->
          <!--                {{ $t("panel.dashboardPage.withdrawalPage.receivedAmount") }}-->
          <!--              </span>-->

          <!--              <div>-->
          <!--                <span class="me-2 gray13&#45;&#45;text">-->
          <!--                  {{-->
          <!--                    selectedCurrency.availableAmount - -->
          <!--                    selectedCurrency.feeAmount-->
          <!--                  }}-->
          <!--                </span>-->

          <!--                <span class="me-2 gray13&#45;&#45;text">-->
          <!--                  {{ selectedCurrency.iso }}-->
          <!--                </span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <v-checkbox
            v-model="withdrawModel.accepted"
            :rules="checkedAddressRules"
            required
            dense
            :ripple="false"
            hide-details="auto"
            off-icon="$emptyCheckbox"
            on-icon="$activeCheckbox"
            class="mb-8 mt-0"
          >
            <template v-slot:label>
              <div class="gray8--text font-regular-12">
                {{
                  $t(
                    "panel.dashboardPage.withdrawalPage.checkWalletAddressDesc"
                  )
                }}
              </div>
            </template>
          </v-checkbox>

          <v-btn
            :disabled="!valid"
            color="primary"
            block
            depressed
            height="47"
            :loading="loading"
            type="submit"
            class="radius-2"
          >
            <span class="font-semiBold-14">
              {{ $t("panel.dashboardPage.withdrawalPage.withdraw") }}
            </span>
          </v-btn>
        </v-form>
      </v-col>

      <div class="ps-5 ps-lg-15 mt-5 mt-md-0 important-box">
        <div class="font-medium-16 gray13--text mb-4">
          {{ $t("panel.dashboardPage.withdrawalPage.important") }}
        </div>

        <ul class="custom-list">
          <li
            v-for="(item, index) in $t(
              'panel.dashboardPage.withdrawalPage.withdrawNotices'
            )"
            :key="index"
            class="mb-3 font-regular-12 gray13--text"
          >
            {{ index + 1 }}. {{ item }}
          </li>
        </ul>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { AccountService } from "@/services";

export default {
  name: "withdrawal",
  components: {},
  data() {
    return {
      selectedCurrencyRegex: "",
      valid: false,
      loading: false,
      submitLoading: false,
      withdrawModel: {
        accountId: null,
        amount: "",
        // tag: "",
        accepted: false,
        withdrawAddress: "",
      },
      noTag: false,
      currencyRules: [
        (v) =>
          !!v || this.$t("panel.dashboardPage.withdrawalPage.currencyNotEmpty"),
      ],
      amountRules: [
        (v) =>
          !!v || this.$t("panel.dashboardPage.withdrawalPage.amountNotEmpty"),
        (v) =>
          (v <= this.selectedCurrency.remainAmount && v > 0) ||
          this.$t("panel.dashboardPage.withdrawalPage.amountNotValid"),
      ],
      // walletAddressRules: [
      //   (v) =>
      //     !!v ||
      //     this.$t("panel.dashboardPage.withdrawalPage.walletAddressNotEmpty"),
      //   (v) =>
      //     /^(?=.*?[A-Za-z])(.*)$/.test(v) ||
      //     this.$t("panel.dashboardPage.withdrawalPage.invalidWalletAddress"),
      // ],
      tagRules: [
        (v) => !!v || this.$t("panel.dashboardPage.withdrawalPage.tagNotEmpty"),
      ],
      checkedAddressRules: [
        (v) =>
          !!v ||
          this.$t("panel.dashboardPage.withdrawalPage.checkedAddressRequired"),
      ],
      currencies: [],
      selectedCurrency: {},
    };
  },
  computed: {
    walletAddressRules() {
      let rules = [];

      if (!this.withdrawModel.withdrawAddress) {
        const required = [
          (v) =>
            !!v ||
            this.$t("panel.dashboardPage.withdrawalPage.walletAddressNotEmpty"),
        ];

        rules = required;
      } else if (
        this.withdrawModel.withdrawAddress &&
        this.selectedCurrencyRegex
      ) {
        const match = [
          (v) =>
            new RegExp(this.selectedCurrencyRegex).test(v) ||
            this.$t("panel.dashboardPage.withdrawalPage.walletAddressNotValid"),
        ];

        rules = match;
      }

      return rules;
    },

    usdValue() {
      return (
        `${this.$t("panel.dashboardPage.withdrawalPage.value")}` +
        `${
          this.removeThousand(this.withdrawModel.amount) *
          this.selectedCurrency.instantAmount
        }`
      );
    },
  },
  mounted() {
    this.loading = true;
    Promise.all([this.getCurrencies(), this.getAccount()])
      .then(() => {
        this.withdrawModel.accountId = this.selectedCurrency.accountId;
      })
      .then(() => {
        this.loading = false;
      });
  },
  methods: {
    removeThousand(value) {
      const str = value.toString();
      return str.replace(/,/gi, "");
    },
    addMaxAmount() {
      this.withdrawModel.amount = this.selectedCurrency.remainAmount;
      document.querySelector(".amount-message-dir input").value =
        this.selectedCurrency.remainAmount;
    },
    submit() {
      this.submitLoading = true;
      AccountService.withdraw(this.withdrawModel)
        .then((res) => {
          this.$router.replace(
            `/panel/dashboard/${res.data.trxId}/withdrawal-report`
          );
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    filterOnlyNumber(evt) {
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^\d+$/.test(expect)) {
        evt.preventDefault();
      }
    },
    getCurrencies() {
      return AccountService.getAllAccountTitles().then((res) => {
        this.currencies = res.data.content;
      });
    },
    getAccount() {
      const query = this.$route.query;
      return AccountService.getAccountByIsoAndNetwork(
        query.from,
        query.network
      ).then((res) => {
        this.selectedCurrency = res.data;
        this.selectedCurrencyRegex = this.selectedCurrency.regex;
      });
    },
    getSelectedCurrencyData() {
      this.withdrawModel.withdrawAddress = "";
      this.withdrawModel.amount = "";
      const currency = this.currencies.find(
        (elem) => elem.accountId === this.withdrawModel.accountId
      );
      if (
        currency.iso === this.selectedCurrency.iso &&
        currency.network === this.selectedCurrency.network
      )
        return;

      this.loading = true;
      this.$router.replace({
        query: {
          from: currency.iso,
          network: currency.network,
        },
      });

      if (this.$route.query.from && this.$route.query.network) {
        this.getAccount()
          .then(() => {
            this.withdrawModel.accountId = this.selectedCurrency.accountId;
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "Withdrawal";
</style>
