<template>
  <v-row v-if="loading" align="center" justify="center" class="fill-height">
    <v-progress-circular
      :size="60"
      :width="5"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-row>

  <v-container v-else>
    <v-card max-width="547" class="mx-auto pa-6 text-center">
      <v-icon size="94" class="mb-3">$hourGlass</v-icon>

      <div class="font-regular-12 gray7--text mb-1">
        {{ $t("panel.dashboardPage.withdrawReport.amount") }}
      </div>

      <div class="mb-1">
        <span class="font-medium-20 gray13--text me-1">
          {{ transactionDetails.amount }}
        </span>

        <span class="font-medium-20 gray13--text">
          {{ transactionDetails.iso }}
        </span>
      </div>

      <div class="font-regular-12 gray7--text mb-5">
        {{ transactionDetails.transactionTime | $renderDate }}
      </div>

      <div class="gray13--text mb-8 px-11">
        {{ $t("panel.dashboardPage.withdrawReport.reportDesc") }}
      </div>

      <!--      <v-btn-->
      <!--        color="primary"-->
      <!--        outlined-->
      <!--        block-->
      <!--        depressed-->
      <!--        height="47"-->
      <!--        :href="transactionDetails.transactionExplorerLink"-->
      <!--        target="_blank"-->
      <!--        class="mb-6"-->
      <!--      >-->
      <!--        <span class="font-semiBold-14 me-3">-->
      <!--          {{ $t("panel.dashboardPage.withdrawReport.goToExplorer") }}-->
      <!--        </span>-->

      <!--        <v-icon size="24">$shareArrow</v-icon>-->
      <!--      </v-btn>-->

      <router-link :to="`/panel/transactions/withdrawal-details/${trxId}`">
        <span class="font-semiBold-14 primary--text">
          {{ $t("panel.dashboardPage.withdrawReport.transactionDetails") }}
        </span>
      </router-link>
    </v-card>
  </v-container>
</template>

<script>
import { AccountService } from "@/services";
import renderDate from "@/mixins/renderDate";

export default {
  name: "withdrawal-report",
  props: ["trxId"],
  mixins: [renderDate],
  components: {},
  data() {
    return {
      loading: false,
      transactionDetails: {},
    };
  },
  computed: {},
  mounted() {
    this.getWithdrawDetails();
  },
  methods: {
    getWithdrawDetails() {
      this.loading = true;
      AccountService.getWithdrawDetails(this.trxId)
        .then((res) => {
          this.transactionDetails = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "WithdrawReport";
</style>
